.ser-sub-hed {
    font-size: 20px !important;
}


.ser-cm-fnt {
    font-size: 16px;
    padding-top: 2%;
}

.pic-1 {
    width: 100%;
    height: 350px;
    background-color: aqua;
}


.benift {
    background-color: green;
    border-radius: 10px;
    margin-bottom: 2%;
    color: white;
    padding: 1% 3%;
    display: flex;
}

.fa-check {
    background-color: white !important;
    color: black !important;
    border: 2px solid gold;
    font-size: 12px;
    padding: 4px;
    border-radius: 50%;
    margin-right: 2%;
}


.irg-img {
    border-radius: 20px;
}

.text-green {
    color: rgb(46, 253, 46);
}

.accordion-button {
    /* background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(25px);
    color: white !important; */
    /* background-image: url('../images/bg2.png'); */
}

.accordion-body {

    background-image: url('../images/bg2.png');

}

.bok-ser {
    position: relative;
    border: 2px solid green !important;
    box-shadow: -5px 5px 0px green !important;
    color: red !important;
    font-size: 16px !important;
    background-color: white !important;
    font-weight: 600 !important;
    margin-top: 5% !important;
    border-radius: 10px !important;
    text-transform: uppercase;
    animation: zoom-in-zoom-out 1.5s ease infinite;
}

.bok-ser span {
    position: absolute;
}

.pop-formbod {
    /* background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(25px); */
}

.modal-content{
    background-color: rgba(0, 0, 0, 0.4) !important;
    backdrop-filter: blur(25px);
    color: white !important;
}

.btn-close{
    background-color: white !important;
}

.ser-sub{
    font-size: 14px;
}

.bok-ser span img {
    width: 50px;
}

@keyframes zoom-in-zoom-out {
    0% {
        scale: 100%
    }

    50% {
        scale: 110%;
    }

    100% {
        scale: 100%;
    }
}

.accordion-button::after {
    background-color: white;
    border-radius: 10px;
}



.accordion-body p {
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(25px);
    margin: 0%;
    padding: 2% 3%;
    border-radius: 20px;
    word-spacing: 1px;
}



.flow-ico {
    /* width: 50px; */
    /* background-color: white !important;
    border: 2px solid gold;
    font-size: 12px;
    padding: 4px;
    border-radius: 50%; */
    /* margin-right: 2%;
    transform: rotate(25deg); */
}