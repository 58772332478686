 @font-face {
   font-family: 'futura';
   src: url('./futura/Futura\ Book\ font.ttf') format('truetype');
   font-weight: bolder;
   font-style: normal;
 }

 /* @font-face {
    font-family: 'YourFontName';
    src: url('./assets/fonts/YourFontName-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }  */

 body {
   background-image: url('./images/lef-bg.jpg');
   font-family: "Poppins", sans-serif !important;
 }

 * {
   /* font-family: 'futura'; */
 }

 .cm-bg {
   background-color: rgb(39, 39, 39);

 }

 .call-us-now {
   background-color: #ff0000 !important;
   color: white !important;
   border-radius: 20px;
   display: flex !important;
 }

 .nav-callbtn {
   background-color: white !important;
   color: black !important;
   border: 2px solid gold;
   font-size: 12px;
   padding: 4px;
   border-radius: 50%;
 }


 .all-ser-drop span {
   background-color: red;
   color: white;
   margin-left: 0%;
   padding: 2% 4%;
   display: flex;
   justify-content: space-between;
   width: 55%;
   /* margin-top: 50px; */
 }

 .kn-mor-drp {
   background-color: white;
   border: 1px solid gold;
   border-radius: 50%;
   color: black;
   padding: 5px;
   /* padding-top: 3%; */
 }

 .all-ser-drop {
   text-decoration: none;

 }


 /* .dropdown-menu {
    background-color: rgba(0, 0, 0, 0.7) !important;
    backdrop-filter: blur(25px);
  }

  .dropdown-menu a{
    color: white !important;

  } */


 .navbar-cont {
   position: fixed;

   width: 100%;
   z-index: 500;

   backdrop-filter: blur(20px);
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   background-color: rgba(255, 255, 255, 0.401);

   /* background-position: -0%; */
 }



 /* nav-log */

 .nav-log {
   width: 150px;
   /* display: none; */
 }

 .logo-brand-sec {
   width: 25%;
 }

 /* banner */

 .banner-cont {
   display: flex;
   align-items: center;
   height: 100vh;
   overflow-y: hidden;
   position: relative !important;
   /* justify-content: end; */
 }

 .dropdown:hover .dropdown-menu {
   display: block;
 }

 .bn-cont {
   overflow-x: hidden !important;
 }

 .first-ban {
   position: absolute;
   top: 2%;
   right: 0%;
   z-index: 700;

 }

 .banner-1 {
   width: 500px;
 }

 .pos-rel-ban {
   position: relative;
 }

 .offer-img {
   position: absolute;
   top: 7%;
   width: 100px;
   right: 0px;
   z-index: 100;
 }


 /* .banner-serv{
  font-size: 85px;
} */


 .banSerChange {
   font-size: 100px;
 }

 .banSerChange {
   opacity: 1;
   transition: opacity 1s ease-in-out;
 }

 .banSerChange {
   background-color: rgb(89 96 79);
   color: white;
 }

 .carousel-control-next-icon,
 .carousel-control-prev-icon {
   background-color: black;
   padding: 5px;
   background-size: 20px;
   border-radius: 50%;
   border: 1.5px solid gold;
 }

 .fade-out {
   opacity: 0;
 }

 .cta-btn {
   position: absolute;
   bottom: -20%;
   right: 10%;
   /* width: 22%; */
   padding: 0.5% 2%;
   border: 0;
   box-shadow: 0px 0px 10px white;
   z-index: 999;
   border-radius: 10px;
   font-size: 20px;
   font-weight: 600;
   text-transform: uppercase;
   color: white;

   background-color: orange;
 }


 .cta-btn-1 {
   padding: 0.5% 2%;
   border: 0;
   box-shadow: 0px 0px 10px white;
   z-index: 999;
   border-radius: 10px;
   font-size: 20px;
   font-weight: 600;
   text-transform: uppercase;
   color: white;

   background-color: orange;
 }


 .banner-img {
   position: absolute;
   right: 0%;
 }

 .banner-text-1 span {
   display: block;
 }

 .txt-get-1 {
   color: #ff8201;
 }

 .txt-get-2 {
   color: #ff0000;
 }

 .txt-get-3 {
   color: #ff8201;
 }



 /* banner section 1 */

 .main-banner-cont {
   height: 100vh;
   display: flex;
   align-items: center;
   overflow-y: hidden;
 }

 .man-img-cont {
   position: relative;
 }

 .pos-rel-banner {
   position: relative !important;
   display: block;
 }

 .banner-text-1 {
   /* position: absolute;
  top: 0%; */
   /* z-index: ; */
   transform: translate(0%, 0%);
   text-align: left;
   font-size: 140px;
   font-weight: 700;
   line-height: 125px;
   text-transform: uppercase;
   /* font-style: italic; */

 }

 .tagline {

   font-size: 40px;
   text-transform: capitalize;
   /* z-index: ; */
   width: 100%;
   /* text-align: center; */
   /* font-style: italic; */
   /* position: absolute; */
   backdrop-filter: blur(20px);
   background-color: rgb(0, 0, 0);
   color: white;
 }

 .tagline span {
   display: inline-block;
 }


 .carousel-inner {
   position: relative;
   width: 100%;
   overflow: visible !important;
 }

 .lab-banner {
   width: 100%;

 }

 .carousel {
   width: 100%;
 }

 .lab-banner2 {
   position: absolute;
   top: -15%;
   right: 0%;
   display: block;
   z-index: 700;
   width: 750px;

   -webkit-transform: scaleX(-1);
   transform: scaleX(-1);

   /* display: block;
  margin-left: auto; */
 }

 /* banner2 */

 .banner2-txt {
   position: absolute;
   top: 50%;
   transform: translate(0%, -50%);
   right: 1%;
   width: 35%;
   font-weight: 700;
   font-size: 50px;

 }

 /*  */

 .our-services {
   background-image: url('./images/crawler.svg') !important;
   background-size: 100px;
   background-repeat: repeat-x;
 }


 /* heading */

 .heading-section {}

 .heading {
   font-size: 35px;
   font-family: 'futura';
   text-transform: uppercase;
   font-weight: 700;
   /* background-color: rgba(255, 255, 255, 0.1); */
   /* padding-top: 4%; */
 }

 .m-tagline {
   font-size: 25px;
   margin-bottom: 3%;
   /* font-weight: 600; */
 }

 .work-stepes {
   font-size: 20px;
 }


 /* steps */

 .howWeWork {

   background-image: url('./images/bg2.png');
   background-size: cover;
   /* background-repeat: no-repeat; */
   /* background-position: -30% 50%; */
   background-color: black;
   color: white;
 }

 .work-step {
   background-color: rgba(255, 255, 255, 0.1);
   backdrop-filter: blur(25px);
   border-radius: 15px;

 }

 .ser-cont {
   height: 50px;
 }

 .sterps-image {
   width: 60px;
 }

 .step-count {
   font-size: 18px;
   color: #ff0000;
   font-weight: 800;
   text-transform: uppercase;
   margin-bottom: 0px;
 }

 .steps {
   font-size: 20px;
   font-weight: 500;
 }



 /* services */

 /* .our-services {
  background-image: url('./images/bg-img2.png');
  background-repeat: no-repeat;
  background-position: -20% 50%;
  background-size: 1000px;
} */

 .ser-pos {
   position: relative;
 }

 .ser-pos-abs {
   /* position: absolute;
  bottom: -20px; */
   /* background-color: #afd275; */
   border-bottom-left-radius: 20px;
   border-bottom-right-radius: 20px;
   backdrop-filter: blur(25px);
   color: #ff8201;
   
   padding: 2% 4%;
   text-align: start;
   display: flex;
   min-height: 60px;
   /* justify-content: center; */
   align-items: center;

   width: 100%;
 }


 .ser-img {
   width: 100%;
   height: 200px;
   object-fit: cover;
   border-radius: 10px;
 }

 .ser-name {
   font-size: 18px;
   /* height: 70px; */
   font-weight: 600;
 }

 .all-ser-btn {
   text-decoration: none;
   font-size: 20px;

   /* width: 25% !important; */
 }

 .all-ser-btn span {
   background-color: #ff0000;
   padding: 1% 2%;
   color: white;
 }

 .ser-card {
   background-color: black;
   backdrop-filter: blur(25px);
   color: white;
   box-shadow: 0px 0px 10px gray;
   /* color: white; */
   padding-bottom: 4%;
   border-radius: 10px;
 }

 .ser-callto-acti {
   display: flex;
   justify-content: space-around;
 }

 .know-mor {
   text-decoration: none;
   border: 2px solid gold;
   border-radius: 15px;
   color: black;
   background-color: white;

 }

 .enq-now {
   text-decoration: none;
   border: 2px solid gold;
   border-radius: 15px;
   color: black;
   background-color: white;
 }

 .know-mor .fa-arrow-right {
   /* transform: rotate(0deg); */
   background-color: gold;
   font-size: 14px;
   margin-left: 20px;
   border-radius: 50%;
   font-weight: 400 !important;
 }

 .enq-now .fa-arrow-right {
   /* transform: rotate(0deg); */
   background-color: gold;
   font-size: 14px;
   margin-left: 20px;
   border-radius: 50%;
   font-weight: 400 !important;
 }

 .serMinCont {
   min-height: 120px;
   text-align: justify;
   padding: 0% 5%;
   font-size: 15px;
 }

 /* testimonials */


 .swiper-slide {
   position: relative;
 }

 .test-cont {
   /* padding-top: 450px; */
   background-color: lightgray;
   min-height: 365px;
   padding: 2%;
   border-radius: 20px;
 }

 .test-abs {
   position: absolute;
   top: -10%;
 }

 .client-img {

   width: 100px;
   height: 100px;
   border-radius: 50%;
   border: 5px solid white;
   object-fit: cover;
   margin-bottom: -45px;
 }

 .test-cont .tesr-p {
   padding-top: 50px;
 }

 .tesr-p span {
   color: #eb9a1a;
   font-size: 25px;
 }

 .cust-name {
   font-size: 20px;
   font-weight: 600;
   color: #eb9a1a;
 }

 .accordion-item {
   color: white !important;
   background-color: transparent !important;
 }

 .faqs {
   /* background-image: url('./images/banner-eg1.png'); */
   background-repeat: no-repeat;
   background-position-y: center;
   background-size: 450px;
 }

 .cust-prof {
   font-size: 15px;
   color: gray;
 }

 .accordion-button {
   font-size: 18px;
   font-weight: 600;
   background-color: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(171, 171, 171, 0.06) 100%) rgba(0, 0, 0, 0) !important;
 }

 .accordion-button:not(.collapsed) {
   background-color: #eb9a1a !important;
   color: white !important;
 }

 .accordion-body {
   background-color: rgba(255, 255, 255, 0.1);
   backdrop-filter: blur(25px);
   border-radius: 15px;
 }


 /* gallery */

 .gal-img {
   width: 100%;
   height: 250px;
   border-radius: 20px;
 }


 /* footer */

 /* .footer {
  position: relative;
  width: 100%;
  background: #3586ff;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */




 footer {
   /* background-image: url('./images/bgimg.jpg'); */
   background-color: black;
 }

 .social-icon,
 .menu {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 10px 0;
   flex-wrap: wrap;
 }

 .social-icon__item,
 .menu__item {
   list-style: none;
 }

 .social-icon__link {
   font-size: 2rem;
   color: #fff;
   margin: 0 10px;
   display: inline-block;
   transition: 0.5s;
 }

 .social-icon__link:hover {
   transform: translateY(-10px);
 }

 .menu__link {
   font-size: 1.2rem;
   color: #fff;
   margin: 0 10px;
   display: inline-block;
   transition: 0.5s;
   text-decoration: none;
   opacity: 0.75;
   font-weight: 300;
 }

 .menu__link:hover {
   opacity: 1;
 }

 .footer {
   position: relative;
 }

 .grass1 {
   position: absolute;
   bottom: 0%;
   left: 0%;
   width: 100px;
 }

 .grass2 {
   position: absolute;
   bottom: 0%;
   right: 0%;
   width: 100px;
 }

 .footer p {
   color: #fff;
   margin: 15px 0 10px 0;
   font-size: 1rem;
   font-weight: 300;
 }

 .ftr-cont {
   /* backdrop-filter: blur(20px);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5); */
 }

 .ftr-ser ul {
   padding-left: 4%;
 }

 .ftr-ser ul li {
   list-style: none;
   padding-top: 5px;
 }

 .ser-catgory {
   text-align: justify;
   padding-left: 5%;
   font-size: 15px;
   padding-bottom: 2%;
 }

 .ftr-hed {
   color: #c3ff62;
   position: relative;
   font-family: 'futura';
 }

 .test-sec{

 }


 .sbtn-form {
   background: #a1c1e6;
   border-radius: 20px;
   border: 1.5px solid #fcb040;
   color: #ff0000;
   font-size: 14px;
   position: relative;
 }

 .bird-img {
   position: absolute;
   top: 5%;
   right: -20%;
   width: 40px;
   /* background-color: white;
  padding: 1%;
  border-radius: 50%; */
 }

 .ftr-ser ul li a {
   color: white;
   /* text-decoration: solid; */

 }

 /* testing */

 .banner-pos {
   height: 110vh;
   display: flex;
   align-items: end;
 }


 .cor-img-banner {
   position: absolute;
   top: -15%;
   right: 0%;
   display: block;
   z-index: 700;
   width: 650px;
 }


 .banner-corsoil {
   width: 100%;

   -webkit-transform: scaleX(-1);
   transform: scaleX(-1);
 }

 .banner-corsoil2 {
   width: 250px;

   -webkit-transform: scaleX(-1);
   transform: scaleX(-1);
 }

 .txt-green {
   color: green !important;
 }

 #enquiry_form_footer input,
 textarea {
   border: 0px;
   border-radius: 5px;
   padding: 2% 3%;
 }

 #enquiry_form_footer input,
 textarea::placeholder {
   font-size: 14px;
 }


 .clWa {

   position: fixed;
   right: 0%;
   top: 45%;

 }


 .clWa .call {
   background-color: red;
   margin-top: 30px;
   width: 40px;
   height: 40px;


 }

 .clWa a {
   color: white;
   font-size: 25px;
   padding: 4%;

 }

 .clWa a .fa-phone {
   color: white;
   font-size: 20px;
   padding: 4%;

 }


 @media only screen and (max-width:760px) {
   .banner-text-1 {
     font-size: 60px;
   }

   .heading {
     font-size: 28px;
   }

   .m-tagline {
     font-size: 18px;
     margin-bottom: 5%;
     /* font-weight: 600; */
   }

   .d-no {
     display: none !important;
   }

   .ser-card{
    margin: 0% 3%;
   }
  


   .ser-callto-acti a {
     /* display: block; */
     font-size: 12px;
     /* width: 85%; */
     margin-left: 4%;
     margin-right: 4%;
     margin-bottom: 5%;
   }

   .soil-enrich .ser-callto-acti a {
     display: block;
     font-size: 13px;
     width: 45%;
     margin: auto !important;
     margin-bottom: 5px !important;
   }

   .ser-catgory {
     text-align: left !important;
     font-size: 13px;
   }

   .ser-name {
     font-size: 16px;
   }

   .serMinCont {
     min-height: 95px;
     text-align: left;
     padding: 0% 5%;
     font-size: 12px;
   }

   .ser-callto-acti {
     display: block !important;
   }

 }

 .nav-rel {
   position: relative;
 }

 .call-us-now {
   background-color: rgb(255 0 0 / 70%) !important;
   /* padding: 2%; */
   backdrop-filter: blur(25px);
   /*color: white !important;
    border-radius: 20px; */
   padding: 2%;
 }


 .nsv-abs {
   position: absolute;
   top: 95%;
   left: 50%;
   transform: translate(-50%);
 }

 .cor-img-banner {
   top: -40%;
   /* right: -5%; */
   width: 100%;
 }

 .banner-pos {
   height: 610px;
   width: 100%;
 }

 .mob-use {
   height: 100vh;
   display: flex;
   align-items: center;
   width: 100%;
 }

 .banSerChange {
   font-size: 25px;
   line-height: 60px;
 }

 .banner-text-1 {
   line-height: 80px;
 }

 .tagline {
   font-size: 25px;
   line-height: 40px;
   text-align: center;
 }

 .mob-banner-cor {
   width: 100%;

   -webkit-transform: scaleX(-1);
   transform: scaleX(-1);
 }

 .txt-get-2,
 .txt-get-2 {
   margin-bottom: 15px;
 }






 .ser-changes {
   position: absolute;
   top: -50%;
   left: 50%;
   transform: translate(-50%, 0%);
   width: 80%;
   text-align: center;
   background-color: rgb(89 96 79);
 }

 .steps {
   font-size: 14px;
   font-weight: 500;
 }


 .soil-enrich .ser-callto-acti {
   display: flex !important;
 }





 .footer-logo {
   display: block;
   margin: auto;
 }


 /* about us */

 .abt-banner {
   position: relative;
 }

 .pag-name {
   position: absolute;
   top: 0%;
   left: 0%;
   right: 0%;
   bottom: 0%;
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: rgba(0, 0, 0, 0.5);
   color: white;
   text-transform: uppercase;
   font-weight: 600;
   /* transform: translate(-50% ,-50%) !important; */
   font-size: 80px;
 }


 .main-pg-header {
   font-size: 40px;
   padding-bottom: 2%;
 }


 

 /*  */





 /* contact  */

 .cont-info a {
   text-decoration: none;
   color: green;
 }

 .social-media {
   display: flex;
   justify-content: space-around;
 }

 .sm-ftr{
  margin-top: 20px !important;
  
  justify-content: space-between !important;
 }

 .pag-name span{
      color: #fa8e22;
 }

 @media only screen and (max-width:760px) {

   .abt-banner img {
     height: 35vh;
     object-fit: cover;
   }


   .pag-name {
     /* background-color: rgba(0, 0, 0, 0.7); */
     color: white;
     font-size: 40px;
     line-height: 50px;
   }

   .pag-name p {
    position:absolute;
    top: 50%;
    transform: translate(0%, -50%);
   }

   .main-pg-header {
     font-size: 30px;
     padding-bottom: 2%;
     line-height: 20px;
   }

   .abt-font {
     font-size: 13px;
   }



 }