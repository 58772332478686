.log-hel {

    /* margin-top: 20%; */
    width: 150px
  }
 
 
  .comp-name {
 
 
   font-family: 'futura';
    src: url('./futura/Futura\ Book\ font.ttf') format('truetype');
    font-weight: bolder;
    font-style: normal;
 
    font-size: 45px;
    color: red;
    margin: 0px;
    /* margin-bottom: 10%; */
    font-weight: 700;
    text-align: center;
    text-transform: uppercase
  }
 
.log-descont{
    width: 100%;
    height: 200px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 200px;
    width: 40%;
} 